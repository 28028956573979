import { ComponentSectionsImageBanner } from "../../lib/remoteGraphQL";
import NextImage from "next/legacy/image";
import { getStrapiMedia } from "@/lib/media";
import CombinedLink from "../CombinedLink";

export const ImageBanner = (props: { model: ComponentSectionsImageBanner | null }) => {
  return <CombinedLink href={props?.model?.url ?? "/"}>
    <div className="hidden md:block w-full aspect-w-10 aspect-h-1 cursor-pointer">
      {props?.model?.image && <NextImage src={getStrapiMedia(props.model.image)} layout="fill" objectFit="contain" objectPosition="center" priority />}
    </div>
    <div className="block md:hidden w-full aspect-w-3 aspect-h-1 cursor-pointer">
      {props?.model?.mobileImage && <NextImage src={getStrapiMedia(props.model.mobileImage)} layout="fill" objectFit="contain" objectPosition="center" priority />}
    </div>
  </CombinedLink>
}
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ComponentSectionsCategoryOverview } from "../../lib/remoteGraphQL";
// import YouTube from "react-youtube";

import moment from "moment";

//component
import CombinedLink from "../CombinedLink";
import ArticleImage from "../article/ArticleImage";
import PaidArticleLabel from "../article/widgets/PaidArticleLabel";
import { Video } from "../article/ArticleHeadMedia";
import { ArticleListItem } from "../article/ArticleListItem";
import dynamic from "next/dynamic";
import { useIsClient } from "usehooks-ts";

const GoogleAds = dynamic(() => import("../GoogleVoldemort"), {
  ssr:false
});

export const CategoryOverview = (props: {
  model: ComponentSectionsCategoryOverview | null;
}) => {
  const { model } = props;
  // const [spaceBoxHeight, setSpaceBoxHeight] = useState<string>('auto');
  const isClient = useIsClient();


  const sideArticles = useMemo(
    () => [...(model?.articles ?? model?.category?.articles ?? [])].splice(1),
    [model]
  );
  const firstArticle = useMemo(
    () => model?.articles?.at(0) ?? model?.category?.articles?.at(0),
    [model]
  );

  return (<>
    <div className="relative">
      <div className="absolute w-full h-full bg-gray-500 dark:bg-dark-content-300 left-0">
        <div
          className="hidden lg:block  z-0 absolute top-0  right-0 w-screen bg-gray-500  h-full  dark:bg-dark-content-300"
        />
        <div
          className=" hidden lg:block  z-0 absolute bottom-0 left-[60%] w-[calc((100vw-100%)/2+40%-1rem-1px)] bg-gray-700 dark:bg-dark-base-200  h-full ml-2"
        />
      </div>
      <div
        className=" flex  flex-col lg:flex-row  relative    w-full   md:container mx-auto"
      >
        <div
          className=" w-full lg:w-[60%] dark:text-white"
        >
          <div className=" w-full lg:pr-6">
            <div className="font-bold text-base lg:text-2xl text-white p-2">
              {model?.title}
              {model?.title != null &&
                      model?.title?.trim()?.length > 0 &&
                      model?.category != null &&
                      " - "}
              {model?.category?.name}
            </div>

            {firstArticle && (
              <>
                {firstArticle?.video == null ? (
                  <div>
                    <ArticleImage
                      article={firstArticle}
                      wide
                      // loader={(props) => props.src}
                      layout="responsive"
                      objectFit="contain"
                      width={16}
                      height={9}
                    />
                  </div>
                ) : (
                  <div className=" pt-[56.25%]  w-full relative">
                    <div className=" absolute top-0 left-0 w-full h-full">
                      <Video
                        video={firstArticle.video}
                        article={firstArticle}
                      />
                    </div>
                  </div>
                )}
                <div className="p-2">
                  <div className="text-white  text-sm lg:text-base mt-2">
                    {isClient?moment(
                      firstArticle?.publishAt ?? firstArticle?.published_at
                    ).fromNow():""}
                  </div>
                  <CombinedLink
                    href={`/${firstArticle?.category?.slug}/${firstArticle?.slug}`}
                    scroll
                    underline="none"
                    color="inherit"
                  >
                    <div className=" text-white dark:text-white text-base lg:text-xl">
                      {firstArticle?.title}
                    </div>
                    <PaidArticleLabel article={firstArticle} />
                  </CombinedLink>
                  <div className="flex items-center justify-center mt-4">
                    <GoogleAds variant="wide"/>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className=" w-full lg:w-[40%] p-2 lg:p-4 lg:pr-0 text-white bg-gray-700 dark:bg-dark-base-200 lg:bg-transparent dark:lg:bg-transparent">
          <div className="w-full lg:pl-6">
            <div className="font-bold text-base lg:text-2xl mb-4" >
                    更多內容
            </div>
            <div className="flex flex-col">
              {sideArticles.map((it, idx) => (
                <div key={idx} className="mb-5">
                  <ArticleListItem article={it!} preferredRatio="1:1"/>
                </div>
              ))}
              <div className="my-6">
                <CombinedLink
                  href={`/${firstArticle?.category?.slug}`}
                  scroll
                >
                  <div className="text-base hover:bg-gray-600 hover:dark:bg-dark-content-300 transition-colors duration-500  cursor-pointer text-primary-500 border-primary-500 border-2 rounded-[20px] h-10 flex items-center justify-center  ">
                          查看所有{model?.category?.name}
                  </div>
                </CombinedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};

import { Box, Container, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NextImage from "next/legacy/image";
import { makeStyles } from 'tss-react/mui';

import { Hero } from './Hero';
import { getStrapiMedia, srcExtractor } from '../../lib/media';
import { ComponentSectionsCategoryInsight } from '../../lib/remoteGraphQL';
import CustDivider from '../CustDivider';
import dynamic from "next/dynamic";
import { FC } from 'react';
const Marquee = dynamic(()=>import("react-fast-marquee"),{
  ssr:false
});


const useStyles = makeStyles()((theme: Theme) =>
  ({
    '@keyframes marquee': {
      '0%': {
        'transform': 'translate(0%, 0)'
      },
      '50%': {
        'transform': 'translate(-100%, 0)'
      },
      '50.001%': {
        'transform': 'translate(100%, 0)'
      },
      '100%': {
        'transform': 'translate(0%, 0)'
      }
    },
    marqueeRoot:{
      display: "block",
      backgroundColor:theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: "absolute",
      left: 0,
      width: "100%",
      height:"256px",
      // overflowX:"hidden"
    }, 
    marqueeParent:{
      // display:"flex",
      // position:"relative",
      // justifyContent: "flex-start",
      // width:"auto",
      // height:"128px",
      // flexDirection:"row",
      // animation: "$marquee 10s linear infinite",
      // animationDelay: "1s",
      // animationFillMode:"forwards",
      // '&::-webkit-animation-fill-mode':"forwards",
      // "&:hover": {
      //   animationPlayState: 'paused'
      // },
    },
    marqueeImages:{

    }
  }));

const CateoryInsightBreifMarquee = (props: {model: ComponentSectionsCategoryInsight | null }) =>{
  const theme=useTheme();
  const { classes }=useStyles();
  const {model} = props;
  return <Box className={classes.marqueeRoot} paddingTop={3} paddingBottom={3}>
    <Container maxWidth="lg">
      <Typography variant="h6" component="h2">{model?.title}{ model?.title!=null && model?.title?.trim()?.length >0&&" - "}{model?.category?.name}</Typography>
    </Container>
    <Box className={classes.marqueeParent}>
      <Marquee gradient={false} speed={50}>
        {model?.images?.map((it,idx)=> <NextImage
          key={it?.id ?? idx}
          objectFit="contain"
          layout='fixed'
          width={250}
          height={128}
          src={getStrapiMedia( it?.formats?.large??it?.formats?.medium??it)}
          alt={it?.alternativeText ?? ""}
          placeholder={"blur"}
          blurDataURL={srcExtractor(getStrapiMedia(it?.formats?.thumbnail))}
          className={classes.marqueeImages}
        />)
        }
      </Marquee>
    </Box>
  </Box>
}

export const CategoryInsight:FC<{model: ComponentSectionsCategoryInsight | null}> =(props: {model: ComponentSectionsCategoryInsight | null}) => {
  const {model} = props;
  return <>
    <CateoryInsightBreifMarquee model={model}/>
    <Box height={"256px"} display="block"/>
    {model?.heros?.map((it, idx)=><Box key={model.id+"Hero"+idx} component="span" display="block"><Hero model={it}/></Box>)}
  </>
}
import { fetchGraphQL } from "@/lib/api";
import { getStrapiMedia, getCorrespondingImage } from "@/lib/media";
import { getPartnerBasicInfo } from "@/lib/queries/partner";
import {
  Typography, useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { ComponentSectionsPartnerListing, PartnerStores } from "../../lib/remoteGraphQL";
import CombinedLink from "../CombinedLink";
import CustDivider from "../CustDivider";
import NextImage from "next/legacy/image";

export const PartnerListing = (props: { model: ComponentSectionsPartnerListing| null }) => {
  const { model } = props;
  const theme = useTheme();
  const [partnerFilterText, setPartnerFilterText] = useState("");
  const [faqFilterText, setFaqFilterText] = useState("");
  const debouncePartnerFilterText = useMemo(()=>debounce(setPartnerFilterText, 500), [setPartnerFilterText]);
  const debounceFaqFilterText = useMemo(()=>debounce(setFaqFilterText, 500), [setFaqFilterText]);
  const partners = useQuery("partners", async () => await fetchGraphQL<{ partnerStores: PartnerStores[] }>(getPartnerBasicInfo).then(it => it.partnerStores));
  const filteredPartners = useMemo(() => partnerFilterText ? partners.data?.filter(it => it.name.toLowerCase().includes(partnerFilterText.toLowerCase())) : partners.data
    , [partnerFilterText, partners]);
  return (
    <div className="flex flex-col gap-2">
      <Typography variant="h6" component="h2" >{model?.title}</Typography>
      {model?.enableSearch &&
       <div className="flex justify-center pt-10">
         <div className="mb-3 xl:w-96">
           <div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
             <input type="search" onChange={(e) => debouncePartnerFilterText(e.target.value)} className="h-16 md:h-10 form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
               placeholder="輸入相關文字..." aria-label="Search" aria-describedby="button-addon2" />
           </div>
         </div>
       </div>
      }
      <div className="flex flex-row gap-3 flex-wrap justify-center pb-8">
        {filteredPartners
          ?.map((it, idx) =>
            <CombinedLink key={it.id} className="flex flex-col" href={`/partner#${it.slug}`} prefetch={false}>
              <div className=" w-28 h-28 relative rounded-lg shadow-md  bg-white">
                <NextImage src={getStrapiMedia(getCorrespondingImage(it.icon))} alt={it.name} layout="fill" className="rounded-lg" objectFit='contain' />
              </div>
              <div className="w-28 truncate  text-center font-thin">
                {it.name}
              </div>
            </CombinedLink>
          )}

      </div>
    </div>
  );
}
import React from "react";
import { makeStyles } from 'tss-react/mui';
import Image from "next/legacy/image";

const useStyles = makeStyles()({
  "bgWrap":{
    width:"100%",
    height:"100%",
    position: "absolute",
    zIndex: -1,
    backgroundColor:"#939597",
  }
});


const DivWithBackground =(props: {children: React.ReactNode, image?: React.ReactElement<typeof Image>} &  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> )=>{
  const {children, image, ...rest} = props;
  const { classes: classNames } =useStyles();
  return <div {...rest} style={{position: "relative"}}>
    <div className={classNames.bgWrap} >
      {/* {image} */}
    </div>
    {children}
  </div>
}
export default DivWithBackground
import Image from "next/legacy/image";
import React from 'react';
import clsx from "clsx"
import CombinedLink from './CombinedLink';
import DivWithBackground from './DivWithBackground';

const SlideImage = (
  props: {
    href?: string;
    src: string | StaticImageData;
    alt: string;
    title?: string;
    isStatic?: boolean;
    isSmallerBreakPoint?: boolean;
    priority: boolean;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { href, src, alt, title, isStatic, priority } = props;

  const tF = (event: MouseEvent) => {
    if (isStatic ?? true) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  return (
    <CombinedLink href={href!} underline="none" color="inherit" onClick={(event)=>{ tF(event as any);}}>
      <DivWithBackground>
        <div ref={ref} className="h-full relative ">
          <div className="h-full relative   ">
            <Image
              objectFit='contain'
              layout="responsive"
              width={16}
              height={9}
              src={src}
              alt={alt}
              quality={75}
              priority={priority}
            />


            { title &&<div className={clsx(`
            bg-gradient-to-t  from-black     to-[transparent]
            absolute bottom-0 w-full h-1/2  text-white py-3 px-8 flex flex-row items-end text-xl`)}  >
              <div className='truncate'>{title}</div>
            </div>}

          </div>
        </div>
      </DivWithBackground>
    </CombinedLink>
  )
};


export default React.forwardRef(SlideImage)

import React from "react";
import { ComponentSectionsHero, Enum_Componentsectionshero_Imageposition } from "../../lib/remoteGraphQL";
import NextImage from "next/legacy/image"
import { getStrapiMedia, srcExtractor } from "../../lib/media";
import CustReactMarkdown from "../CustReactMarkdown";
import {
  Box,
  Button,
  Grid,
  Hidden,
  Theme,
  Typography, useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CombinedLink from "../CombinedLink";
import CustDivider from "../CustDivider";
const useStyles = makeStyles({
  //name: "MuiCustomStyle"
})((theme: Theme) =>
  ({
    heroRoot: {
      //        width:"100%",
      minHeight: "500px",
      padding: theme.spacing(3)
    }
  }));
export const Hero = (props: { model: ComponentSectionsHero | null }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { model } = props;

  return (
    <Grid container
      spacing={3}
      direction={ matchesSmUp ?(model?.imagePosition == Enum_Componentsectionshero_Imageposition.ImageOnLeft ? "row" : "row-reverse" ): "column"}
      className={classes.heroRoot}
    >   
      <Hidden smUp>
        <Grid item  xs={12} sm={6}>
          <Typography variant="h6" component="h2">{model?.title}</Typography>
        </Grid>
      </Hidden>
      <Grid item  xs={12} sm={6} sx={{width:"100%", height: "auto"}}>      
        <Box sx={{width:"100%", paddingTop: "56.25%", height: "auto", paddingBottom:theme.spacing(6), position:"relative"}}>
          <NextImage
            key={model?.id}
            objectFit="contain"
            layout='fill'
            // width={16}
            // height={9}
            src={getStrapiMedia(model?.image?.formats?.large??model?.image?.formats?.medium??model?.image)}
            alt={model?.image?.alternativeText ?? model?.title ?? ""}
            placeholder={"blur"}
            blurDataURL={srcExtractor(getStrapiMedia(model?.image?.formats?.thumbnail))}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
        <Hidden smDown>
          <Typography variant="h6" component="h2">{model?.title}</Typography>
        </Hidden>
        <CustReactMarkdown >
          {model?.content ?? ""}
        </CustReactMarkdown>
        {model?.detailUrl && <Box textAlign="end" position="absolute" right="0" bottom="0" >
          <CombinedLink href={model?.detailUrl}>
            <Button
              endIcon={<ArrowForwardIosIcon />}
            >
              更多
            </Button>
          </CombinedLink>
        </Box>}

      </Grid>
    </Grid >
  );
}
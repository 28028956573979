import React from "react"
import ClipLoader  from "react-spinners/ClipLoader";
export const ClipLoaderComponent=(props?:any)=>{
  const {...rest }=props

  return (
    <div>
      <ClipLoader color={"#f5df4d"} size={45} {...rest} />
    </div>
  )
}
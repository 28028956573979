import {  gql } from '@apollo/client';
import { fragBasicImage } from './fragments';

export const fragCoupon= gql`
${fragBasicImage}
fragment BasicPartnerCoupon on PartnerCoupons{
    id
    slug
    title
    description
    image{
      ...BasicImage
    }
    effectiveTo
    effectiveFrom
    couponCode
    termsAndConditions
    requiredMemberTier
    codeDisplayFormat
}
`

export const getAllPartnerSlug=  gql`
query getAllPartnerSlug{
  partnerStores (sort: "published_at:desc"){
    slug
  }
}
`

export const getAllPartnerSlugCoupon=  gql`
query getAllPartnerSlug($now:String!) {
  partnerStores (sort: "published_at:desc") {
    slug
    partner_coupons(where:{
      _and: [
        {
          _or: [
            { effectiveFrom_lte: $now }
            { effectiveFrom_null: true }
          ]
        }
        { 
          _or: [
            { effectiveTo_gte: $now },
            { effectiveTo_null: true }
          ]
        }
      ]
    }){
      slug
    }
  }
}
`

export const getPartnerBasicInfo=  gql`
${fragBasicImage}
query getPartnerBasicInfo{
  partnerStores{
    id
    slug
    name  
    icon{
    ...BasicImage
    }
    url
  }
}
`

export const getPartner=  gql`
${fragBasicImage}
${fragCoupon}
query getPartnerWithCoupon($slug:String!,  $now: String!){
  partnerStores (where :{slug: $slug}, limit: 1 ) {
    id
    slug
    name  
    description
    icon{
    ...BasicImage
    }
    headerImage{
    ...BasicImage
    }
    url
    termsAndConditions
    partner_coupons(where:{
      _and: [
        {
          _or: [
            { effectiveFrom_lte: $now }
            { effectiveFrom_null: true }
          ]
        }
        { 
          _or: [
            { effectiveTo_gte: $now },
            { effectiveTo_null: true }
          ]
        }
      ]
    }){
      ...BasicPartnerCoupon
    }
  }
}
`

export const getPartnersOnly=  gql`
${fragBasicImage}
query getPartnersOnly{
  partnerStores{
    id
    slug
    name
    description
    category
    icon{
    ...BasicImage
    }
    headerImage{
    ...BasicImage
    }
    url
    priority
    termsAndConditions    
  }
}
`

export const getPartners=  gql`
${fragBasicImage}
${fragCoupon}
query getPartnerWithCoupon( $now: String!){
  partnerStores{
    id
    slug
    name
    description
    category
    icon{
    ...BasicImage
    }
    headerImage{
    ...BasicImage
    }
    url
    priority
    termsAndConditions
    partner_coupons(where:{
      _and: [
        {
          _or: [
            { effectiveFrom_lte: $now }
            { effectiveFrom_null: true }
          ]
        }
        { 
          _or: [
            { effectiveTo_gte: $now },
            { effectiveTo_null: true }
          ]
        }
      ]
    }){
      ...BasicPartnerCoupon
    }
  }
}
`

export const getPartnerCoupons= gql`
query getCoupons( $now: String!){
  partnerCoupons(where:{
      _and: [
        {
          _or: [
            { effectiveFrom_lte: $now }
            { effectiveFrom_null: true }
          ]
        }
        { 
          _or: [
            { effectiveTo_gte: $now },
            { effectiveTo_null: true }
          ]
        }
      ]
    }){
    slug
  }
}
`

export const getRecentPartnerCoupons=gql`
${fragBasicImage}
${fragCoupon}
query getCoupons( $now: String!){
  partnerCoupons(
      sort: "effectiveFrom:desc",
      where:{
      _and: [
        {
          _or: [
            { effectiveFrom_lte: $now }
            { effectiveFrom_null: true }
          ]
        }
        { 
          _or: [
            { effectiveTo_gte: $now },
            { effectiveTo_null: true }
          ]
        }
      ]
    }
  ){
    ...BasicPartnerCoupon
    partner_store{
      name
    }
    }
}`
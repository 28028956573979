import 'moment/locale/zh-tw';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Card, CardActionArea, CardActions, CardContent, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';

import { Article } from '../../lib/remoteGraphQL';
import CombinedLink from '../CombinedLink';
import ArticleImage from './ArticleImage';
import ArticleShareButton from './widgets/ArticleShareButton';
import PaidArticleLabel from './widgets/PaidArticleLabel';
import { useIsClient } from 'usehooks-ts';

//icon
import { ReactComponent as  PlanIcon} from '@/assets/play.svg';
import { ReactComponent as LikeIcon } from '@/assets/article/cc-like.svg';
import { ReactComponent as CommentIcon } from '@/assets/article/icon_cmmt.svg';
import { EnrichedArticle } from './InfiniteArticles';

const useStyles = makeStyles<{ article?: Article | null, height?: number, overflow?: boolean }>()(theme => ({
  cardMedia: {
    display: "relative",
    objectFit: "cover",
    width: "100%",
    height: "auto",
    paddingTop: "100%",
  },
  cardMediaImage: {
    display: "absolute",
    objectFit: "cover",
    width: "100%",
    height: "auto",
    paddingTop: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  videoLabel: {
    opacity: 0.8
  },
  previewCardTitle: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.getContrastText(theme.palette.grey[200]),
    minHeight: "5.5rem",
    [theme.breakpoints.down('md')]: {
      minHeight: "4.5rem",
      padding: "18px 16px",
    },
  },
  previewCardAction: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.getContrastText(theme.palette.grey[200]),
    paddingTop: "0rem",
  },
  previewCardContentContainer: {
    zIndex: 0,
    background: "inherit",
    //boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .2)",	
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, .8)",
    backdropFilter: "blur(5px)",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    opacity: 0,
    transition: "0.5s",
    '&:hover': {

      opacity: 1
    }
  },
  popoverWithArrow: {

    // zIndex: theme.zIndex.tooltip,
    // overflowX: "unset",
    // overflowY: "unset",
    // "&::before": {
    //     content: '""',
    //     position: "absolute",
    //     bottom: 0,
    //     left: '50%',
    //     width: 10,
    //     height: 10,
    //     backgroundColor: theme.palette.background.paper,
    //     boxShadow: theme.shadows[1],
    //     transform: "translate(-50%, 50%) rotate(135deg)",
    //     clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    // },
  },
  textOverflow: {
    [theme.breakpoints.up('md')]: {
      display: "-webkit-box",
      overflow: "hidden",
      lineClamp: 2,
      boxOrient: "vertical",
    }
  },

  previewCardContainer: {
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "6px",
    borderRadius: "4px",
    border: "1px rgb(219,219,219) solid",

    [theme.breakpoints.up("sm")]: {
      marginBottom: "0",
    },
  }

}));

moment.locale('zh-tw')
const ArticlePreviewView = (props: { article?: EnrichedArticle | null, height?: number, overflow?: boolean, priority?:boolean ,fromCampaign?:boolean,
fromMember?:boolean
}) => {
  // console.log("isBrowser", isBrowser)
  // console.log("isMobile", isMobile);
  const theme = useTheme();
  const { classes, cx } = useStyles(props);
  const { article, height, priority,fromCampaign, fromMember } = props;
  const isClient = useIsClient();


  return article == null ?
    <Skeleton /> :
    <div className='rounded-md bg-white flex flex-col dark:bg-dark-base-200'>
      <CombinedLink href={`/${article?.category?.slug}/${article?.slug}`} scroll underline="none" color="inherit">
        <div className='relative cover w-full h-auto pt-[100%] rounded-t-md '>
          <ArticleImage
            priority={priority}
            article={article}
            layout='fill'
            // loader={({src, width, quality})=>src+"?&w="+width+"&q="+(quality || 75)}
            className={`
                                absolute object-cover w-full h-auto aspect-w-1 aspect-h-1 rounded-md
                                `}
          />
          {(article?.video?.youtubeUrl?.length ?? 0) > 0 ?
            <div className={` absolute p-2 bg-primary-500 text-white dark:text-dark-content-100 dark:bg-dark-base-200 bottom-0 left-0 rounded-tr-md 
                            items-center text-center justify-center w-10 h-10 flex  `}>
              {/* <Box position="absolute" p={1} bgcolor="secondary.main" color={theme.palette.secondary.contrastText} className={classes.videoLabel} right={0} top={0} alignItems="center" textAlign="center" alignContent="center" > */}
              <PlanIcon className={"w-5 h-5"} />
              {/* <Typography>
                                        {moment.duration(article?.video?.videoLength, 'seconds').asMinutes().toFixed().toString().padStart(2,"0")}:{(moment.duration(article?.video?.videoLength, 'seconds').asSeconds()%60).toFixed().padStart(2,"0")}
                                    </Typography> */}
            </div>
            : <>
            </>
          }
          {(isBrowser&&isClient) &&
                        <div className={`rounded-md mobile:hidden absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center text-center
                                opacity-0 hover:opacity-100 z-0 transition-opacity duration-500
                                backdrop-blur-md bg-white/80  dark:bg-dark-base-200/80 dark:text-white` }>
                          {/* <Typography color="inherit" variant="subtitle1">{article?.description}</Typography> */}
                          <Typography color="inherit" variant="subtitle1" sx={{ display: "-webkit-box", WebkitLineClamp: 9, WebkitBoxOrient: "vertical", overflow: "hidden", margin: "1rem", textAlign: "left" }}>{article?.description}</Typography>
                        </div>
          }
          <div className="absolute right-0 bottom-0 p-2 w-fit h-fit ">
            <PaidArticleLabel article={article} />
          </div>
        </div>
        <div className='px-2 md:px-4'>
          {!fromMember&&<div className='text-gray-500 dark:text-dark-content-200 text-xs  mt-2'>
            {article?.category?.name}
          </div>}
          <div className={`min-h-[3.5rem]  pt-2  dark:text-dark-content-100`} >
            <div  className={cx("generic-link text-sm lg:text-lg", props.overflow ? classes.textOverflow : "")}>
              {article?.title}
            </div>
          </div>
        </div>
      </CombinedLink>
      <div className='flex items-center w-full  px-2 md:px-4 pb-2 md:pb-4 mt-2'>
        <div className="text-gray-500  dark:text-dark-content-200 grow">          
          <div  className='text-xs lg:text-sm flex flex-row items-center gap-2 flex-wrap' >
            {fromMember?<span>
              {
                fromCampaign?"已參與":
                  isClient ? moment(article?.publishAt ?? article?.published_at).fromNow() : '-'
              }
            </span>:
              <span>{isClient ? moment(article?.publishAt ?? article?.published_at).fromNow() : '-'}</span>
            }
            <span><LikeIcon className='w-4 h-4 inline-block mr-1' />{article?.meta?.like}</span>
            <span><CommentIcon className='w-4 h-4 inline-block mr-1' />{article?.meta?.comment}</span>
          </div>
        </div>
        <div>
          <ArticleShareButton article={article} className='z-auto relative p-1 w-6 h-6 lg:w-7 lg:h-7' utmMedium="ccpvshare"/>
        </div>
      </div>
    </div>
}


export default ArticlePreviewView
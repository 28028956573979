import { Article, ComponentSharedYoutubeVideo } from '@/lib/remoteGraphQL';
import dynamic from 'next/dynamic';
import { useCallback, useRef, useState } from 'react';
import { LoadingIcon } from '../fancy/LoadingIcon';
import clsx from 'clsx';
import UploadFileGalleryItem from '../gallery/UploadFileGalleryItem';
import ArticleImage from './ArticleImage';

const ReactPlayer = dynamic(() => import("react-player/lazy"), {ssr:false, loading:()=><div className='w-full aspect-w-16 aspect-h-9 rounded-2xl border-gray-500 bg-black/80'></div>})

const ArticleHeadMedia = ({ article }: { article: Article }) => {
  
  return (
    <>
      {!article.video?.videoId && !article.video?.vimeoId ? 
        <div className="sm:w-full sm:!mx-auto md:w-full md:!mx-auto lg:w-full">
          <div className={clsx('relative', article.wideImage?"aspect-h-9 aspect-w-16":" aspect-h-1 aspect-w-1")}>
            <UploadFileGalleryItem image={article?.wideImage ?? article?.image} title={article?.title} >
              <ArticleImage
                blur={false}
                priority
                article={article} wide layout={"fill"} objectFit="contain"
                // loader={(props) => props.src}
                // {...smDown ? {
                //   width: article.wideImage != null ? 16 : 1,
                //   height: article.wideImage != null ? 10 : 1
                // } : {
                //   width: 16,
                //   height: 9
                // }}
              />
            </UploadFileGalleryItem>
          </div>
        </div>
        :
        <Video video={article.video} article={article} />
      }
    </>
  );
}



export const Video = ({ video, article }: { video: ComponentSharedYoutubeVideo, article:Article }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [ratio, setRatio] = useState(1);
  const [ready, setReady] = useState(false);

  const onReady = useCallback(() => {
    const iframe = wrapperRef.current?.children[0]?.children[0]?.children[0];

    if (!iframe) return;
    const width = iframe.getAttribute("width");
    const height = iframe.getAttribute("height");

    if (width && height) {
      setRatio(parseFloat(width) / parseFloat(height));
    }
    setReady(true);
  }, []);

  const url = (video.vimeoId 
    ? `https://vimeo.com/${video.vimeoId}` 
    : video.youtubeUrl) ?? ""

  return (
    <div
      className="relative w-full xl:w-full mx-auto"
      ref={wrapperRef}
    >
      <ReactPlayer
        className={"div:absolute div:top-0 div:left-0 relative aspect-w-16 aspect-h-9"}
        url={url}
        width="100%"
        height="auto"
        style={{
          // aspectRatio: `${ratio}`,
        }}
        controls
        onReady={onReady}
      />
      {!ready&&<div className='w-full h-full'>
        <ArticleImage
          className='rounded-2xl'
          blur={false}
          priority
          article={article} wide layout={"fill"} objectFit="contain"
          width={16}
          height={9}
        />
        <div className={"absolute top-0 left-0 w-full h-full  bg-black/50 rounded-2xl flex flex-col justify-center items-center z-10 p-30 border-2 border-gray-500 backdrop-blur-sm"}>
          <LoadingIcon />
        </div>
      </div>
      }
    </div>
  );
}

export default ArticleHeadMedia;
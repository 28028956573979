import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/pagination';

import { getArticleImageUrl, getArticleThumbnailImageAltText } from '@/lib/media';
import { createRef, useEffect, useMemo, useState } from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { makeStyles } from 'tss-react/mui';
import clsx from "clsx"
import { Article, ComponentSectionsArticleSlider } from '../../lib/remoteGraphQL';
import SlideImage from '../SlideImage';


const useStyles = makeStyles<{ swiper: any }>()((theme, { swiper }) => ({
  swiper: {
    width: "100%",
    height: "100%",

    "&>.swiper": {
      paddingBottom:"30px",
      
      zIndex: 0,
      width: "100%",
      "&>.swiper-wrapper": {
        zIndex: 0,
        "&>.swiper-slide": {
          transform: swiper ? "" : "translate(100vw, 0)",
          "&:nth-of-type(2) .bgCover": {
            display: swiper ? "block" : "none",
          },
          [theme.breakpoints.up("md")]: {
            width: "40rem",
            marginRight: "12px",
            transform: swiper
              ? "translate(calc(50vw - 50% - 20rem+12px), 0)"
              : "translate(calc(50vw - 150% - 20rem+12px), 0)",
          },
        },
      },

      "&>.swiper-pagination": {
        bottom: "0px",
        "&>.swiper-pagination-bullet": {
          width: "8px",
          height: "8px",
          marginLeft: "0.5rem",
          marginRight: "0.5rem",
        },
      },
    },
  },
}));

function Carousel(props: {
  model: Array<Article> | null;
}) {
  const [swiper, setSwiper] = useState<any>(null);

  const { classes } = useStyles({ swiper });
  const { model } = props;
  const SlideImageRef = createRef<HTMLDivElement>();

  const slides = useMemo(
    () =>
      model?.map((a, i) => (
        <SwiperSlide key={`home-article-slide-${a.slug}`}>
          {({ isActive }) => (
            <div className="w-full md:rounded-md overflow-hidden">
              <SlideImage
                ref={SlideImageRef}
                href={`/${a?.category?.slug}/${a?.slug}`}
                src={getArticleImageUrl(a, true)}
                alt={getArticleThumbnailImageAltText(a)}
                title={a?.title}
                priority={i < 3}
              />
              {/* {!isActive && <div className="bgCover cursor-grab absolute left-0 top-0 w-full h-full" style={{background:" rgba(2, 2, 2, 0.50)"}}></div>} */}
            </div>
          )}
        </SwiperSlide>
      )),
    [SlideImageRef, model]
  );

 

  return ( 
    <>
      <div className={clsx( classes.swiper,"dark:bg-dark-main  relative",
        `
        [&>*>*>.swiper-pagination-bullet]:bg-[#666666]
        [&>*>*>.swiper-pagination-bullet]:dark:bg-white
        `
      )} 
      >
        {!swiper?<div className="absolute w-full h-full  overflow-hidden">
          <div className='absolute left-0 flex flex-nowrap gap-3'>
            <div className='bg-gray-500 rounded-md w-[40rem] h-[calc(40rem*9/16)] animate-pulse'/>
            <div className='bg-gray-500 rounded-md w-[40rem] h-[calc(40rem*9/16)] animate-pulse'/>
            <div className='bg-gray-500 rounded-md w-[40rem] h-[calc(40rem*9/16)] animate-pulse'/>
          </div>
        </div>:<>
        </>}
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          breakpoints={{
            768: {
              slidesPerView: 'auto',
            },
          }}
          initialSlide={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          loop
          loopedSlides={model?.length??1}
          onSwiper={setSwiper}
        >
          {slides}
          <SlidePrevButton />
          <SlideNextButton />
        </Swiper>
      </div>
    </>
  );
}

export const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      className="p-[10px] w-[40px] h-[40px] absolute top-[50%] right-[16px] -translate-y-[calc(50%+15px)] rounded-[50%] dark:bg-dark-main/80 hover:dark:bg-dark-main bg-gray-500/80 hover:bg-gray-500"
      aria-label={"Next"}
      onClick={() => swiper.slideNext()}
    >
      <svg viewBox="0 0 100 100">
        <path
          fill="#f5df4d"
          d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
          transform="translate(100, 100) rotate(180) "
        ></path>
      </svg>
    </button>
  );
};
export const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      className="p-[10px] w-[40px] h-[40px] absolute top-1/2 left-[16px] -translate-y-[calc(50%+15px)] rounded-[50%] dark:bg-dark-main/80 hover:dark:bg-dark-main bg-gray-500/80 hover:bg-gray-500"
      aria-label={"Previous"}
      onClick={() => swiper.slidePrev()}
    >
      <svg viewBox="0 0 100 100">
        <path fill="#f5df4d" d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"></path>
      </svg>
    </button>
  );
};


export const ArticleSlider = (props: { model: ComponentSectionsArticleSlider | null }) => {
  const { model } = props;

  return <div className='md:container mx-auto lg:px-auto   relative'>
    <div className="  ">
      <div className="font-bold text-base pl-4 md:pl-0  md:text-2xl  dark:text-white mb-3 md:mb-4 ">
        {model?.title}{model?.title != null && model?.title?.trim()?.length >0 && model?.category!=null && " - "}{model?.category?.name}
      </div>
    </div>
    <div className=' w-full  h-auto md:min-h-96'>
      <Carousel model={((model?.articles?.length ?? 0) > 0 ? model?.articles : model?.category?.articles) as Array<Article>} />
    </div>
  </div>;
}
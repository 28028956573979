import { CategoryCustomPageBlocksDynamicZone, HomepageBlocksDynamicZone } from "@/lib/remoteGraphQL";
import { AdOverlay } from "./AdOverlay";
import { ArticleListing } from "./ArticleListing";
import { ArticleSlider } from "./ArticleSlider";
import { CategoryInsight  } from "./CategoryInsight";
import { CategoryOverview } from "./CategoryOverview";
import { Hero } from "./Hero";
import { HotTopics } from "./HotTopics";
import { ImageBanner } from "./ImageBanner";
import { PartnerListing } from "./PartnerListing";
import { TextBlock } from "./TextBlock";

export const SectionSelector= <T extends HomepageBlocksDynamicZone | CategoryCustomPageBlocksDynamicZone,>(props: {sectionProps: T | null})=>{
  const {sectionProps} = props;
  switch(sectionProps?.__typename){
  case "ComponentSectionsAdOverlay":
    return <AdOverlay model={sectionProps}/>;
  case "ComponentSectionsCategoryInsight":
    return <CategoryInsight model={sectionProps}/>;
  case "ComponentSectionsCategoryOverview":
    return <CategoryOverview  model={sectionProps}/>;
  case "ComponentSectionsHero":
    return <Hero  model={sectionProps}/>
  case "ComponentSectionsHotTopics":
    return <HotTopics  model={sectionProps}/>;
  case "ComponentSectionsArticleSlider":
    return <ArticleSlider  model={sectionProps}/>;
  case "ComponentSectionsImageBanner":
    return <ImageBanner  model={sectionProps}/>;

  case "ComponentSectionsTextBlock":
    return <TextBlock  model={sectionProps}/>;
  case "ComponentSectionsPartnerListing":
    return <PartnerListing  model={sectionProps}/>;
  case "ComponentSectionsArticleListing":
    return <ArticleListing  model={sectionProps}/>;

  }
  return <> </>
}
import {
  Typography, useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ComponentSectionsTextBlock } from "../../lib/remoteGraphQL";
import CustReactMarkdown from "../CustReactMarkdown";

export const TextBlock = (props: { model: ComponentSectionsTextBlock | null }) => {
  const { model } = props;
  const theme = useTheme();

  return (
    <div className="flex flex-col gap-2">
      <Typography variant="h6" component="h2" >{model?.title}</Typography>
      <CustReactMarkdown>
        {model?.content ?? ""}
      </CustReactMarkdown>
    </div>
  );
}
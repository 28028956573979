import { fetchGraphQL } from '@/lib/api';
import { getRecentArticle, getRecentArticleOfCategories } from '@/lib/queries';
import React from 'react';

import { Article, ComponentSectionsHotTopics } from '../../lib/remoteGraphQL';
import InfiniteArticles from '../article/InfiniteArticles';
import NormalArticles from '../article/NormalArticles';
import CombinedLink from '../CombinedLink';
import CustDivider from '../CustDivider';
import dynamic from "next/dynamic";
import clsx from "clsx"
const GoogleAds = dynamic(() => import("../GoogleVoldemort"), {
  ssr:false
});
const hashtagSimplify = (str:string) => str.replace(/(<.*?>)|(&.*?;)/gm, "").replace( /\s\s+/g, ' ' ).trim();

const chineseSlugify = (str: string) => str.replace(/[^(\u4E00-\u9FFF0-9a-zA-Z-)]/g, "-");
export const HotTopics = (props: { model: ComponentSectionsHotTopics | null }) => {
  const { model } = props;
  const fetchArticles = ({ pageParam = 0 }) => fetchGraphQL<{ articles: Article[] }>(
    (model?.categories?.length??0)>0 ? getRecentArticleOfCategories: getRecentArticle, 
    { start: pageParam, catIds: model?.categories?.map(it=>+ (it?.id ??"0")) })?.then(it => it.articles ?? []);

  return <div className="flex flex-col-reverse  lg:grid grid-cols-4 gap-6 ">

    <div className="col-span-12 lg:col-span-3 px-2 lg:px-0 "  id={"hotTopics"}>
      <div className="w-full px-2 lg:px-0">
        <div className=' font-bold dark:text-white text-base lg:text-2xl' >{model?.title}</div>
      </div>
      {
        model?.infiniteScroll ? 
          <>
            <InfiniteArticles displayMode={"Normal"} queryKey={['articles', 'recent',]} queryFn={fetchArticles} defaultList={model?.articles??[]} />
          </> 
          :
          <NormalArticles displayMode={"Normal"} queryKey={['articles', 'recent']} queryFn={fetchArticles} defaultList={model?.articles??[]}/>
      }
    </div>
    <div  className="px-4 lg:px-0  col-span-1 w-full lg:border-l  lg:pl-5 ">
      <GoogleAds  variant="square" className=" hidden lg:block aspect-1 w-full mb-4" />

      <div className=" w-full">
        <div className='dark:text-white text-base lg:text-2xl font-bold mb-4' >更多 #hashtag</div>
      </div>
   
      <div  className='dark:text-white flex flex-row flex-wrap items-end'  >
        {(model?.arrayOfTags as string[])?.map((tag, idx) => <div  key={tag + idx} 
          className={clsx(" mb-4 lg:mb-5 mr-6 whitespace-nowrap flex items-center justify-center",
            idx==0? "h-10 lg:h-12  bg-gray-500 dark:bg-dark-content-200 rounded-3xl px-3 lg:px-4 text-primary-500 font-bold text-2xl lg:text-3xl"
              :"text-base lg:text-lg  h-8 bg-gray-200  dark:bg-dark-content-300 rounded-2xl  px-3")}>
          <CombinedLink href={`/tag/${chineseSlugify(hashtagSimplify(tag.toLocaleLowerCase()))}`} underline="none" color="inherit">
            #{hashtagSimplify(tag)}
          </CombinedLink>
        </div>
        )}
      </div>
      <div className=' block lg:hidden  p-5'>
        <GoogleAds  variant="square" className=" block lg:hidden aspect-1 w-full " />
      </div>
      <GoogleAds  variant="long" className="  hidden lg:block sticky top-16" />
    </div>
  </div>
}
import { fetchGraphQL } from "@/lib/api";
import { getCategoryArticle, getCategoryArticleWithLimit } from "@/lib/queries";
import {
  Grid,
  Typography, useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { Category, ComponentSectionsArticleListing } from "../../lib/remoteGraphQL";
import ArticlePreviewView from "../article/ArticlePreview";
import InfiniteArticles from "../article/InfiniteArticles";
import CustDivider from "../CustDivider";

export const ArticleListing = (props: { model: ComponentSectionsArticleListing | null }) => {
  const { model } = props;
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const fetchArticles = ({ pageParam = 0 }) => fetchGraphQL<{ categories: Category[] }>
  (getCategoryArticle, { slug: model?.category?.slug, start: pageParam }).then(res => res.categories[0]?.articles || []);

  const preloadedArticles = useMemo(() =>
    (model?.articles != null && model?.articles?.length > 0) ? model.articles :
      (model?.category?.articles != null && model?.category?.articles?.length > 0) ? model.category.articles :
        []
  , [model])
  const staticArticles = useQuery(["static", "articleList", model?.category?.slug??""], async ()=>{
    return await fetchGraphQL<{ categories: Category[] }>
    (getCategoryArticleWithLimit, { slug: model?.category?.slug, start: 0, limit: model?.limitArticle??8 }).then(res => res.categories[0]?.articles || []);
  }, {
    initialData: preloadedArticles
  });
  return (
    <div className="flex flex-col gap-2">
      <Typography variant="h6" component="h2" >{model?.title}</Typography>
      {
        model?.infinityScroll ?
          <InfiniteArticles md={3} displayMode="Normal" queryKey={['articles', model?.category?.slug]}
            queryFn={fetchArticles} defaultList={model?.category?.articles ?? []} /> :
          <Grid container spacing={mdUp ? 3 : 1}>
            {staticArticles.data?.map((art, idx) =>
              <React.Fragment key={art?.slug}>
                <Grid item md={3} sm={12} xs={12}>
                  <ArticlePreviewView overflow={true} article={art!} key={art?.id} height={400} />
                </Grid>
                {/* {
            (idx%(adsInterval??7))==1 && idx!=0 && 
            <Grid item md={3} sm={12} xs={12}>
            <GoogleAds variant="article-preview" className="w-full rounded bg-white"/>
            </Grid>
          }  */}
              </React.Fragment>
            )}
          </Grid>
      }

    </div>
  );
}
import { Maybe, Article } from "@/lib/remoteGraphQL";
import { Box, Grid, Hidden, GridSize, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { QueryKey, QueryFunction, useInfiniteQuery } from "react-query";

import ArticlePreviewView from "./ArticlePreview";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import clsx from 'clsx';
import dynamic from "next/dynamic";
import { useSession } from "next-auth/react";
import {ClipLoaderComponent} from "@/components/ClipLoaderComponent"
import { fetchAPI } from "@/lib/api";
const GoogleAds = dynamic(() => import("../GoogleVoldemort"), {
  ssr:false,
  loading: () => <div className="h-96 w-full rounded bg-white dark:bg-dark-base-200"></div>,
});

type ArticleMeta = {
  like?:number
  comment?:number
  save?:number
  view?:number
}
export type EnrichedArticle = Article & {
  meta?:ArticleMeta
}

export type DisplayMode =  "Normal";
const InfiniteArticles  = <TQueryKey extends QueryKey = QueryKey >(props:{adsInterval?: number,displayMode: DisplayMode, queryKey: TQueryKey, queryFn: QueryFunction<Maybe<Article>[] | Article[], TQueryKey>, defaultList?: Maybe<Article>[],md?: GridSize})=>{
  const {displayMode, adsInterval, defaultList, md} = props;

  const {data: sessionData} = useSession();
  const enrichedQueryFn = useCallback<QueryFunction<Maybe<EnrichedArticle>[] | EnrichedArticle[], TQueryKey>>(async(context)=>{
    let result:Maybe<EnrichedArticle>[] | EnrichedArticle[] = [];
    result = await props.queryFn(context);
    if(result){
      const ids = result.filter(it=>!!it).map(it=>it!.id);
      const meta = await fetchAPI<{[key:string]:ArticleMeta}>(`/articles/meta?ids=${ids.join(",")}&features=like,comment`);
      result = result.map(article => {
        if(!article) return article;
        return {...article, meta: meta[article?.id??-1] }
      });
    }
    return result;
  }
  , [props])
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(props.queryKey, enrichedQueryFn, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.length > 1)
        return pages.reduce((a, b) => a + b.length, 0);
    }, //item per page
  })


  return (
    <InfiniteScroll
      className="min-h-screen h-full dark:text-white"
      style={{ padding: 0, margin: 0, overflow: "hidden", marginTop: "2.8rem" }}
      dataLength={data?.pages?.reduce((a, b) => a + b.length, 0) ?? 0}
      next={fetchNextPage}
      hasMore={hasNextPage || false}
      loader={<></>}
      endMessage={!isFetching &&<div className="flex items-center justify-center p-8 dark:text-dark-content-200">
        <span>
          <SentimentDissatisfiedIcon />
        </span>
        <span>
      已經是全部的文章了
        </span>
      </div>}
    >
      <div className={clsx("h-full grid grid-cols-1 gap-4", md===3?"sm:grid-cols-4":"sm:grid-cols-3" )}>
        {(((data?.pages?.length??0)>0 ? data?.pages?.flatMap(it => it): defaultList) ?? []).map((art, idx) => <React.Fragment key={`${art?.slug}_${idx}`}>
          <div>
            <ArticlePreviewView overflow={true} article={art} key={art?.id} height={400} priority={idx<6} />
          </div>
          {
            (idx%(adsInterval??7))==1 && idx!=0 ?
              <GoogleAds variant="article-preview" className="w-full rounded bg-white dark:bg-dark-base-200" adsId={Math.round(idx/(adsInterval??7))} /> :
              <>
              </>
          } 
        </React.Fragment> 
        )}
      </div>
      {isFetching && <div className="flex items-center justify-center p-8">
        <span>
          <ClipLoaderComponent />
        </span>
        <span className="ml-2">
          載入中
        </span>
      </div>}
    </InfiniteScroll>
  );
}
export default InfiniteArticles;
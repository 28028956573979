import { Maybe, Article } from "@/lib/remoteGraphQL";
import {
  Button, 
} from "@mui/material";

import React from "react";
import { QueryKey, QueryFunction, QueryFunctionContext } from "react-query";
import ArticlePreviewView from "./ArticlePreview";
import { useRouter } from 'next/dist/client/router'
import dynamic from "next/dynamic";
import {ClipLoaderComponent} from "@/components/ClipLoaderComponent"
import clsx from "clsx"

//icon
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const GoogleAds = dynamic(() => import("../GoogleVoldemort"), {
  ssr:false
});


export type DisplayMode = "Normal";
const NormalArticles  = <TQueryKey extends QueryKey = QueryKey >(props:{adsInterval?: number,  displayMode: DisplayMode, queryKey: TQueryKey, queryFn: QueryFunction<Maybe<Article>[] | Article[], TQueryKey> , defaultList?: Maybe<Article>[]})=>{

  const {displayMode, adsInterval, defaultList} = props;

  const [data, setData] = React.useState([] as Maybe<Article>[] | Article[]);
  const [pageNum, setPageNum] = React.useState(0);

  const [isEnd, setIsEnd] = React.useState<boolean>(false);
  const [isloading, setIsLoading] = React.useState<boolean>(false);
  const router = useRouter();
  const [category, setCategory] = React.useState(router.query.category);
  const queryFnCustom = async()=>{
    setIsLoading(true);
    const result : Maybe<Article>[] | Article[] = await props.queryFn({pageParam:pageNum, queryKey:props.queryKey} as QueryFunctionContext<TQueryKey, any>);
    setIsLoading(false);
    if(result.length > 1){
      setPageNum(pageNum + 6);
      setData([...data, ...result]);
      if(result.length != 6){
        setIsEnd(true);
      }
    }else{
      setIsEnd(true);
    }
  }

  const resetParam = ()=>{
    setData([]);
    setPageNum(0);
    setIsEnd(false);
    setIsLoading(false);
  }

  // React.useEffect(()=>{
  //   console.log("resetParam");
  //   if(data.length > 1 ){
  //     resetParam();
  //   }
  // }, [router.query.category]);

  React.useEffect(()=>{
    if(category != router.query.category){
      setCategory(router.query.category);
      resetParam();
    }
  }, [router.query.category]);

  React.useEffect(()=>{
    queryFnCustom();
  }, [category]);

  return <>
    <div>
      <div className={clsx("h-full grid grid-cols-1 gap-4 sm:grid-cols-3" )}>
        {[...(data?.flatMap(it => it) ??defaultList ?? [])].map((art, idx) =><React.Fragment key={art?.slug}>
          <div className=" w-full h-full">
            <ArticlePreviewView overflow={true} article={art!} key={art?.id} height={400} />
          </div>
          {
            idx%(adsInterval??7)==0 && idx!=0 ?
              <GoogleAds variant="article-preview"  className="w-full rounded bg-white dark:bg-dark-base-200 "
                adsId={Math.round(idx/(adsInterval??7))}
              />
              
              :<>
              </>
          }
        </React.Fragment> 
        )}
      </div>
      <div className=" w-11/12 mx-auto md:w-full text-center pt-5 dark:text-dark-content-200">
        {isloading?
          <>
            <div className="flex items-center justify-center p-8">
              <ClipLoaderComponent />
              <span className="dark:text-dark-content-200 ml-2">
          載入中
              </span>
            </div>
          </>
          :
          <>
            {isEnd ?
              <>
                <p><SentimentDissatisfiedIcon /></p>
                <p>已經是全部的文章了</p>
              </> :
              <Button onClick={()=>{queryFnCustom();}}>
                <p>載入更多</p>
              </Button>
            }
          </>
      
        }

      </div>

    </div>
  </>;
}
export default NormalArticles;
import { getCorrespondingImage, getStrapiMedia, toNextImageSrc } from "@/lib/media";
import { UploadFile } from "@/lib/remoteGraphQL";
import React from "react";
import { ItemProps } from "react-photoswipe-gallery";
import SrcGalleryItem from "./SrcGalleryItem";

const UploadFileGalleryItem =(props: Omit<ItemProps, 'children'>& { children: JSX.Element, image?:UploadFile | null})=>{
  const {image,children, ...rest} = props;
  return <SrcGalleryItem 
    src={toNextImageSrc(getStrapiMedia(image)?.toString())}
    thumbnail={toNextImageSrc(getStrapiMedia(getCorrespondingImage(image, null, "thumbnail"))?.toString())}
    width={image?.width ?? undefined}
    height={image?.height  ?? undefined}
    {...rest}
  >
    {children}
  </SrcGalleryItem>
}
export default UploadFileGalleryItem;